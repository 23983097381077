<template>
  <div>
    <div id="widgets" class="row">
      <div v-if="widgetEnabled('ask_admin_question')" :data-name="widgetPriority('ask_admin_question')" class="item col-lg-4 col-md-6"><AskAdminQuestion></AskAdminQuestion></div>
      <div v-if="widgetEnabled('debt')" :data-name="widgetPriority('debt')" class="item col-lg-4 col-md-6"><Debt></Debt></div>
      <div v-if="widgetEnabled('certificate')" :data-name="widgetPriority('certificate')" class="item col-lg-4 col-md-6"><Certificate></Certificate></div>
      <div v-if="widget_integration_otp" :data-name="widgetPriority('integration_otp')" class="item col-lg-4 col-md-6"><OrderedPasses></OrderedPasses></div>
      <div v-if="widgetEnabled('booking')" :data-name="widgetPriority('booking')" class="item col-lg-4 col-md-6"><Bookings></Bookings></div>
      <div v-if="widgetEnabled('meeting_room')" :data-name="widgetPriority('meeting_room')" class="item col-lg-4 col-md-6"><MeetingRoom></MeetingRoom></div>
      <div v-if="widgetEnabled('sheets_for_printing')" :data-name="widgetPriority('sheets_for_printing')" class="item col-lg-4 col-md-6"><SheetsForPrinting></SheetsForPrinting></div>
      <div v-if="widgetEnabled('events')" :data-name="widgetPriority('events')" class="item col-lg-4 col-md-6"><Events></Events></div>
      <div v-if="widgetEnabled('offers')" :data-name="widgetPriority('offers')" class="item col-lg-4 col-md-6"><Offers></Offers></div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import MeetingRoom from './widgets/MeetingRoom'
import OrderedPasses from './widgets/OrderedPasses'
import SheetsForPrinting from './widgets/SheetsForPrinting'
import AskAdminQuestion from './widgets/AskAdminQuestion'
import Certificate from './widgets/Certificate'
import Debt from './widgets/Debt'
import Bookings from './widgets/Bookings'
import Events from './widgets/Events'
import Offers from './widgets/Offers'
export default {
  components: {
    MeetingRoom,
    OrderedPasses,
    SheetsForPrinting,
    AskAdminQuestion,
    Certificate,
    Debt,
    Bookings,
    Events,
    Offers,
  },
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      coworkingById: 'spaces/coworkingById',
      locationsList: 'spaces/list',
      loaded: 'dashboard/loaded',
      packages: 'dashboard/packages',
      orders: 'dashboard/orders',
      bookings: 'dashboard/bookings',
      events: 'dashboard/events',
      offers: 'dashboard/offers',
      integrations: 'main/integrations'
    }),
  },
  data: () => {
    return {
      widget_integration_otp: false,
      widgets: [
        { name: 'ask_admin_question', priority: 0, enabled: false }, // Задать вопрос Администратору
        { name: 'debt', priority: 1, enabled: false }, // Задолженность
        { name: 'certificate', priority: 2, enabled: false }, // Остаток по сертификату
        { name: 'integration_otp', priority: 3 }, // Заказанные пропуски
        { name: 'booking', priority: 4, enabled: false }, // Ближайшие бронирования
        { name: 'meeting_room', priority: 5, enabled: false }, // Часы на переговорные комнаты
        { name: 'sheets_for_printing', priority: 6, enabled: false }, // Баланс листов для печати
        { name: 'events', priority: 7, enabled: false }, // Ближайшие мероприятия
        { name: 'offers', priority: 8, enabled: false }, // Предложения от партнеров
      ]
    }
  },
  mounted() {
    for (let i = 0; i < this.widgets.length; ++i) {
      let name = this.widgets[i].name;
      this.widgets[i].enabled = name !== 'integration_otp' ? window.__env[`widget_${name}`] : null;
      this.widgets[i].priority = window.__env[`widget_priority_${name}`] || window.__env[`widget_priority_${name}`] === 0 ? window.__env[`widget_priority_${name}`] : this.widgets[i].priority;
      // console.log(i, name, this.widgets[i].priority);
    }

    this.OrdersInit().then(() => {
      this.widget_integration_otp = this.integrations && this.integrations.indexOf('integration_otp') > -1;
      this.BookingsInit().then(() => {
        // WIDGETS PRIORITY
        const elements = [...document.querySelectorAll('.item')]
        const parentElement = elements[0].parentNode;
        elements.sort((elementA, elementB) => {
          return +elementA.dataset.name - +elementB.dataset.name;
        }).forEach(element => parentElement.appendChild(element));
        // END: WIDGETS PRIORITY
        if (this.coworkingById && this.widget_integration_otp) {
          this.PassesInit(this.coworkingById.id).then(() => {
            this.TargetInit(this.coworkingById.id);
          });
        }
      });
    });
  },
  methods: {
    ...mapActions('dashboard', [
      'PackagesInit',
      'OrdersInit',
      'BookingsInit',
      'EventsInit',
      'OffersInit',
      'PassesInit',
      'TargetInit'
    ]),
    widgetEnabled(name) {
      let item = this.widgets.find(item => item.name === name);
      return item && item.enabled ? item.enabled : false;
    },
    widgetPriority(name) {
      let item = this.widgets.find(item => item.name === name);
      return item && item.priority ? item.priority : -1;
    },
  },
  watch: {
    locationsList: {
      handler(newList) {
        if (newList !== undefined && newList.length > 0) {
          this.EventsInit(this.locationsList).then(() => {
            this.OffersInit(this.locationsList).then(() => {
              this.PackagesInit(this.locationsList);
            });
          });
        }
      },
      immediate: true,
    },
    packages: {
      handler() {
      },
      deep: true,
    },
  },
}
</script>
